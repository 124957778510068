<template>
	<div class="page">
		<el-row v-if="$store.getters.korisnikGrupa <= 2" :gutter="20">
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.ukupno_karata')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.ukupno_karata, 0)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.ukupno_karata_1_pravac')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.ukupno_karata_1_pravac, 0)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.ukupno_karata_povratna')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.ukupno_karata_povratna, 0)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.ukupno_putnika')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.ukupno_putnika, 0)}}</h1>
				</el-card>
			</el-col>
		</el-row>
		<el-row v-if="$store.getters.korisnikGrupa <= 2" :gutter="20">
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.ukupno_putnika_povratna')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.ukupno_putnika_povratna, 0)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.ukupno_putnika_1_pravac')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.ukupno_putnika_1_pravac, 0)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.ukupna_cena')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.ukupna_cena, 2)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.ukupna_doplata')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.ukupna_doplata, 2)}}</h1>
				</el-card>
			</el-col>
		</el-row>
		<el-row v-if="$store.getters.korisnikGrupa <= 2" :gutter="20">
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.prosek_po_pravcu')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.prosek_po_pravcu, 2)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.prosek_za_1_pravac')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.prosek_za_1_pravac, 2)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash">
					<h2>{{$t('finansije.prosek_za_povratnu')}}</h2>
					<h1>{{$utils('formatBroja', statistika.finansije.prosek_za_povratnu, 2)}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-loading="!statistika.kurs.eur">
				<el-card shadow="hover" class="text-right">
					<div>
						<h1>{{ statistika.kurs.datum ? $utils('formatDate', statistika.kurs.datum) : '-' }}</h1>
					</div>
					<div>
						<h2>EUR {{ statistika.kurs.eur ? $utils('formatBroja', statistika.kurs.eur, 4) : '-' }}</h2>
					</div>
					<div>
						<h2>CHF {{ statistika.kurs.chf ? $utils('formatBroja', statistika.kurs.chf, 4) : '-' }}</h2>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :xs="24" :sm="12" :md="6" :lg="3" :xl="3" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash row0">
					<h2>{{$t('statusi.cekanje')}}</h2>
					<h1>{{statistika.karte_po_statusu.cekanje ? $utils('formatBroja', statistika.karte_po_statusu.cekanje, 0) : 0}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="3" :xl="3" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash row-1-128">
					<h2>{{$t('statusi.odbijena')}}</h2>
					<h1>{{statistika.karte_po_statusu.odbijena ? $utils('formatBroja', statistika.karte_po_statusu.odbijena, 0) : 0}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash row-78">
					<h2>{{$t('statusi.ostalo')}}</h2>
					<h1>{{statistika.karte_po_statusu.ostalo ? $utils('formatBroja', statistika.karte_po_statusu.odbijena, 0) : 0}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash row1">
					<h2>{{$t('statusi.placeno')}}</h2>
					<h1>{{statistika.karte_po_statusu.placeno ? $utils('formatBroja', statistika.karte_po_statusu.placeno, 0) : 0}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash row120">
					<h2>{{$t('statusi.refund')}}</h2>
					<h1>{{statistika.karte_po_statusu.refund ? $utils('formatBroja', statistika.karte_po_statusu.refund, 0) : 0}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="3" :xl="3" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash row777">
					<h2>{{$t('statusi.six_rezervacija')}}</h2>
					<h1>{{statistika.karte_po_statusu.six_rezervacija ? $utils('formatBroja', statistika.karte_po_statusu.six_rezervacija, 0) : 0}}</h1>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="12" :md="6" :lg="3" :xl="3" v-loading="!statistika.karte_po_statusu">
				<el-card shadow="hover" class="text-right box-dash row110">
					<h2>{{$t('statusi.storno')}}</h2>
					<h1>{{statistika.karte_po_statusu.storno ? $utils('formatBroja', statistika.karte_po_statusu.storno, 0) : 0}}</h1>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="24">
				<el-card shadow="hover">
					<el-col :xs="24" :sm="24" :md="6" :lg="3" :xl="3" v-loading="!statistika.godine">
						<el-select v-model="heatMap.godina" @change="daniHeatmap();heatMap.idLinije = null;">
							<el-option v-for="val in statistika.godine" :key="val" :value="val" :label="val"></el-option>
						</el-select>
					</el-col>
					<el-col :xs="24" :sm="24" :md="6" :lg="3" :xl="3" v-loading="!statistika.linije[heatMap.godina]">
						<el-select v-model="heatMap.idLinije" @change="daniHeatmap()" clearable>
							<el-option v-for="val in statistika.linije[heatMap.godina]" :key="val.id" :value="val.id" :label="val.naziv"></el-option>
						</el-select>
					</el-col>
					<el-col :xs="24" :sm="24" :md="6" :lg="3" :xl="3">
						<el-select v-model="heatMap.odakleIde" @change="daniHeatmap()">
							<el-option label="iz Srbije" :value="true"/>
							<el-option label="ka Srbiji" :value="false"/>
						</el-select>
					</el-col>
					<el-col :xl="24">
						<v-chart style="width: auto" v-if="statistika.godine && statistika.linije[heatMap.godina]" :options="vHeatMap" :autoresize="true" v-loading="vHeatMapLoading" @click="downloadDan"/>
					</el-col>
				</el-card>
			</el-col>
		</el-row>
		<el-row v-if="$store.getters.korisnikGrupa <= 2" :gutter="20">
			<el-col :span="24">
				<el-card shadow="hover">
					<v-chart style="width: auto" :autoresize="true" :options="vBarMap"/>
				</el-card>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<el-card shadow="hover">
					<posete></posete>
				</el-card>
			</el-col>
		</el-row>
		<el-row v-if="$store.getters.korisnikGrupa <= 2" :gutter="20">
			<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
				<el-card shadow="hover" v-loading="!vPieChartKupljeneKarteLoader">
					<v-chart style="width: auto" :autoresize="true" :options="vPieChartKupljeneKarte"/>
				</el-card>
			</el-col>
			<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
				<el-card shadow="hover" v-loading="!vPieChartEtapaKarteLoader">
					<v-chart style="width: auto" :autoresize="true" :options="vPieChartEtapaKarte"/>
				</el-card>
			</el-col>
		</el-row>
		<el-row v-if="$store.getters.korisnikGrupa <= 2" :gutter="20">
			<el-col :span="24">
				<el-card shadow="hover">
					<el-row>
						<el-col>
							<h1>{{$t('dashboard.gridSerachLogTekst')}}</h1>
						</el-col>
					</el-row>
					<el-row>
						<!--Table-->
						<el-table v-if="statistika.searchLog.data.length"
									:data="statistika.searchLog.data"
									ref="table"
									v-loading="statistika.searchLog.load"
									border
									stripe>
							<el-table-column
									width="100"
									align="left"
									prop="search_datetime"
									:label="$t('dashboard.datum')">
								<template slot-scope="scope">
									{{$utils('formatDate', scope.row.search_datetime, 'DD.MM.YYYY HH:MM')}}
								</template>
							</el-table-column>
							<el-table-column
									align="left"
									prop="naziv_linije"
									:label="$t('dashboard.linija')">
							</el-table-column>
							<el-table-column
									width="125"
									align="left"
									prop="broj_karte"
									:label="$t('dashboard.broj_karte')">
								<template slot-scope="scope">
									<el-link @click="$utils('goTo', '/karte?brojKarte=' + scope.row.broj_karte)">{{scope.row.broj_karte}}</el-link>
								</template>
							</el-table-column>
							<el-table-column
									align="center"
									prop="mesto_polaska"
									:label="$t('dashboard.mesto_polaska')">
							</el-table-column>
							<el-table-column
									align="center"
									prop="odrediste"
									:label="$t('dashboard.odrediste')">
							</el-table-column>
							<el-table-column
									width="100"
									align="center"
									:label="$t('dashboard.polazak') + ' / ' + $t('dashboard.povratak')">
								<template slot-scope="scope">
									{{$utils('formatDate', scope.row.polazak)}}
									<el-divider></el-divider>
									{{$utils('formatDate', scope.row.povratak)}}
								</template>
							</el-table-column>
							<el-table-column
									align="center"
									prop="vrsta_karte"
									:label="$t('dashboard.vrsta_karte')">
								<template slot-scope="scope">
									<span v-if="scope.row.vrsta_karte === 1">{{$t('dashboard.jedan_pravac')}}</span>
									<span v-if="scope.row.vrsta_karte === 2">{{$t('dashboard.povratna')}}</span>
								</template>
							</el-table-column>
							<el-table-column
									width="80"
									align="right"
									prop="broj_putnika"
									:label="$t('dashboard.broj_putnika')">
							</el-table-column>
							<el-table-column
									width="90"
									align="right"
									prop="cena"
									:label="$t('dashboard.cena')">
								<template slot-scope="scope">
									{{$utils('formatBroja', scope.row.cena)}}
								</template>
							</el-table-column>
							<el-table-column
									width="70"
									align="left"
									prop="valuta"
									:label="$t('dashboard.valuta')">
							</el-table-column>
							<el-table-column
									align="left"
									prop="kupon"
									:label="$t('dashboard.kupon')">
							</el-table-column>
							<el-table-column
									width="110"
									align="center"
									prop="lastminute"
									:label="$t('dashboard.lastminute')">
								<template slot-scope="scope">
									<i v-if="scope.row.lastminute === 1" class="fas fa-check-circle color-text--green"></i>
									<i v-else class="fas fa-times-circle color-text--red"></i>
								</template>
							</el-table-column>
							<el-table-column
									width="80"
									align="center"
									prop="popust"
									:label="$t('dashboard.popust')">
								<template slot-scope="scope">
									<i v-if="scope.row.popust === 1" class="fas fa-check-circle color-text--green"/>
									<i v-else class="fas fa-times-circle color-text--red"/>
								</template>
							</el-table-column>
							<el-table-column
									width="120"
									align="left"
									prop="ip_adresa"
									:label="$t('dashboard.ip_address')">
								<template slot-scope="scope">
									<el-tooltip placement="top">
										<div slot="content">
											<div>{{scope.row.drzava}} - {{scope.row.region}}</div>
											<div>{{scope.row.provajder}}</div>
										</div>
										<a :href="'https://ip-api.com/#' + scope.row.ip_adresa" target="_blank">{{scope.row.ip_adresa}}</a>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
									width="100"
									align="center"
									prop="etapa"
									:label="$t('dashboard.etapa')">
								<template slot-scope="scope">
									<el-progress v-if="scope.row.etapa === 1000" status="exception" :percentage="25"/>
									<el-progress v-else-if="scope.row.etapa === 1100" status="warning" :percentage="50"/>
									<el-progress v-else-if="scope.row.etapa === 1110" status="warning" :percentage="75"/>
									<el-progress v-else-if="scope.row.etapa === 1111" status="success" :percentage="100"/>
								</template>
							</el-table-column>
							<el-table-column
									width="100"
									align="center"
									prop="platforma"
									:label="$t('dashboard.platforma')">
							</el-table-column>
						</el-table>
						<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
					</el-row>
					<br>
					<el-row>
						<pagination v-model="statistika.searchLog.pagination" @input="getStatistikaSearchLog" ref="pagination"/>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'Dashboard',
	data() {
		return {
			gridLoad: true,
			vHeatMapLoading: false,
			statistika: {
				karte_po_statusu: {},
				finansije: {},
				kurs: {},
				godine: [],
				linije: [],
				searchLog: {
					load: true,
					data: [],
					pagination: {
						page: 1,
						limit: 10,
						total: 0
					}
				}
			},
			heatMap: {
				idLinije: null,
				godina: null,
				odakleIde: true,
				podaciSvi: []
			},
			vBarMap: {
				title: {
					text: ''
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					data: []
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				yAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				xAxis: {
					type: 'category',
					data: [
						this.$t('meseciSkraceno.jan'),
						this.$t('meseciSkraceno.feb'),
						this.$t('meseciSkraceno.mar'),
						this.$t('meseciSkraceno.apr'),
						this.$t('meseciSkraceno.maj'),
						this.$t('meseciSkraceno.jun'),
						this.$t('meseciSkraceno.jul'),
						this.$t('meseciSkraceno.avg'),
						this.$t('meseciSkraceno.sep'),
						this.$t('meseciSkraceno.okt'),
						this.$t('meseciSkraceno.nov'),
						this.$t('meseciSkraceno.dec')
					]
				},
				series: []
			},
			vHeatMap: {
				tooltip: {
					position: 'center',
					formatter: (p) => {
						let format = this.$utils('formatDate', p.data[0]);
						if (this.heatMap.idLinije) {
							let brojKarata1 = this.heatMap.podaciSvi[p.data[0]].broj_karata1 || 0;
							let brojPutnika1 = this.heatMap.podaciSvi[p.data[0]].broj_putnika1 || 0;
							let ukupnoCena1 = this.heatMap.podaciSvi[p.data[0]].ukupno_cena1 || 0;
							let ukupnoDoplata1 = this.heatMap.podaciSvi[p.data[0]].ukupno_doplata1 || 0;
							let total1 = this.heatMap.podaciSvi[p.data[0]].total1 || 0;

							let brojKarata2 = this.heatMap.podaciSvi[p.data[0]].broj_karata2 || 0;
							let brojPutnika2 = this.heatMap.podaciSvi[p.data[0]].broj_putnika2 || 0;
							let ukupnoCena2 = this.heatMap.podaciSvi[p.data[0]].ukupno_cena2 || 0;
							let ukupnoDoplata2 = this.heatMap.podaciSvi[p.data[0]].ukupno_doplata2 || 0;
							let total2 = this.heatMap.podaciSvi[p.data[0]].total2 || 0;

							let htmlTable = '<table>' +
												'<tbody>' +
													'<tr>' +
														'<td style="padding: 10px">' +
														'<b>' + this.$t('dashboard.datum') + ':</b> ' + format + '<br>' +
														'</td>' +
													'</tr>' +
													'<tr>' +
														'<td style="padding: 10px">' +
														'<b>' + this.$t('dashboard.u_jednom_pravcu') + ':</b>' + '<br>' +
														this.$t('dashboard.broj_karata') + ': ' + brojKarata1 + '<br>' +
														this.$t('dashboard.broj_putnika') + ': ' + brojPutnika1 + '<br>' +
														this.$t('dashboard.ukupna_cena') + ': ' + ukupnoCena1 + '<br>' +
														this.$t('dashboard.ukupna_doplata') + ': ' + ukupnoDoplata1 + '<br>' +
														this.$t('dashboard.total') + ': ' + total1 + '<br>' +
														'</td>' +
														'<td style="padding: 10px">' +
														'<b>' + this.$t('dashboard.povratno') + '</b>:' + '<br>' +
														this.$t('dashboard.broj_karata') + ': ' + brojKarata2 + '<br>' +
														this.$t('dashboard.broj_putnika') + ': ' + brojPutnika2 + '<br>' +
														this.$t('dashboard.ukupna_cena') + ': ' + ukupnoCena2 + '<br>' +
														this.$t('dashboard.ukupna_doplata') + ': ' + ukupnoDoplata2 + '<br>' +
														this.$t('dashboard.total') + ': ' + total2 + '<br>' +
														'</td>' +
														'<td style="padding: 10px">' +
														'<b>' + this.$t('dashboard.ukupno') + '</b>:' + '<br>' +
														this.$t('dashboard.broj_karata') + ': ' + (brojKarata1 + brojKarata2) + '<br>' +
														this.$t('dashboard.broj_putnika') + ': ' + (brojPutnika1 + brojPutnika2) + '<br>' +
														this.$t('dashboard.ukupna_cena') + ': ' + (ukupnoCena1 + ukupnoCena2) + '<br>' +
														this.$t('dashboard.ukupna_doplata') + ': ' + (ukupnoDoplata1 + ukupnoDoplata2) + '<br>' +
														this.$t('dashboard.total') + ': ' + (total1 + total2) +
														'</td>' +
													'</tr>' +
												'</tbody>' +
											'</table>';
							return htmlTable;
						}
						else
							return 'Datum: ' + format + ' <br> ' + 'broj karata:' + p.data[1];
					}
				},
				visualMap: {
					min: 0,
					max: 10,
					calculable: true,
					// type: 'piecewise',
					orient: 'horizontal',
					left: 'center',
					top: 100,
					inRange: {
						color: ['#dedee4', '#006BB3']
					}
				},
				calendar: {
					top: 225,
					left: 30,
					right: 30,
					cellSize: ['auto', 20],
					range: this.moment().format('Y'),
					itemStyle: {
						normal: { borderWidth: 0.5 }
					},
					dayLabel: {
						margin: 5,
						nameMap: [
							this.$t('global.daniSkraceno.7'),
							this.$t('global.daniSkraceno.1'),
							this.$t('global.daniSkraceno.2'),
							this.$t('global.daniSkraceno.3'),
							this.$t('global.daniSkraceno.4'),
							this.$t('global.daniSkraceno.5'),
							this.$t('global.daniSkraceno.6')
						],
						firstDay: 1 // start on Monday
					},
					monthLabel: {
						nameMap: [
							this.$t('meseci.jan'),
							this.$t('meseci.feb'),
							this.$t('meseci.mar'),
							this.$t('meseci.apr'),
							this.$t('meseci.maj'),
							this.$t('meseci.jun'),
							this.$t('meseci.jul'),
							this.$t('meseci.avg'),
							this.$t('meseci.sep'),
							this.$t('meseci.okt'),
							this.$t('meseci.nov'),
							this.$t('meseci.dec')
						]
					},
					yearLabel: { show: true }
				},
				series: {
					type: 'heatmap',
					coordinateSystem: 'calendar',
					data: []
				}
			},
			vPieChartKupljeneKarteLoader: true,
			vPieChartKupljeneKarte: {
				title: {
					text: this.$t('dashboard.kako_je_kupljena_karta'),
					x: 'center'
				},
				tooltip: {
					trigger: 'item',
					formatter: '{b} : {c} ({d}%)'
				},
				legend: {
					top: 30,
					orient: 'vertical',
					left: 'left',
					data: ['web', 'mobile', 'staro']
				},
				series: {
					type: 'pie',
					radius: '55%',
					center: ['50%', '60%'],
					data: [],
					itemStyle: {
						emphasis: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}
			},
			vPieChartEtapaKarteLoader: true,
			vPieChartEtapaKarte: {
				title: {
					text: this.$t('dashboard.etapa_karata'),
					x: 'center'
				},
				tooltip: {
					trigger: 'item',
					formatter: '{b} : {c} ({d}%)'
				},
				legend: {
					top: 30,
					orient: 'vertical',
					left: 'left',
					data: [this.$t('dashboard.etapa1'), this.$t('dashboard.etapa2'), this.$t('dashboard.etapa3'), this.$t('dashboard.etapa4')]
				},
				series: {
					type: 'pie',
					radius: '55%',
					center: ['50%', '60%'],
					data: [],
					itemStyle: {
						emphasis: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}
			},
			vPieChartDokleJeStiglo: {
				title: {
					text: this.$t('dashboard.dokle_je_stigla_kupovina'),
					x: 'center'
				},
				tooltip: {
					trigger: 'item',
					formatter: '{b} : {c} ({d}%)'
				},
				legend: {
					top: 30,
					orient: 'vertical',
					left: 'left',
					data: ['1000', '1100', '1110', '1111']
				},
				series: {
					type: 'pie',
					radius: '55%',
					center: ['50%', '60%'],
					data: [],
					itemStyle: {
						emphasis: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('dashboard.hello')
		});
		this.$utils('stopLoadingAfter', [this.heatmapOpcije(), this.daniHeatmap()]);
		this.dashboardStatistika();
		this.kursDanas();
		this.nacinProdaje();
		this.getStatistikaSearchLog();
		this.etapaKarataProdaje();
	},
	methods: {
		async downloadDan(e) {
			if (!this.heatMap.godina) {
				this.$alert(this.$t('dashboard.izaberiGodunu'), '', {
					confirmButtonText: this.$t('global.uredu')
				});
			}
			else if (!this.heatMap.idLinije) {
				this.$alert(this.$t('dashboard.izaberiLiniju'), '', {
					confirmButtonText: this.$t('global.uredu')
				});
			}
			else {
				this.vHeatMapLoading = true;
				this.$api.statistikaHeatmapDownload.get({ godina: this.heatMap.godina, idLinija: this.heatMap.idLinije, izSrbije: this.heatMap.odakleIde, datum: e.value[0] }).then((response) => {
					return response;
				}).then((response) => {
					window.open(this.$hostname + response.body, '_blank');
					this.vHeatMapLoading = false;
				});
			}
		},
		kursDanas() {
			return this.$get('statistikaKursDanas').then(data => {
				this.statistika.kurs = data;
			});
		},
		dashboardStatistika() {
			return this.$get('statistikaDashboardStatistika').then(data => {
				this.statistika.karte_po_statusu = data.karte_po_statusu;
				this.statistika.finansije = data.finansije;
				let podaci = data.putnici_po_kalendaru;
				let barPodaci = [];
				let barLegend = [];
				for (let godina in podaci) {
					if (podaci.hasOwnProperty(godina)) {
						let arrPutnici = [];
						barLegend.push(godina);
						for (let putnici in podaci[godina])
							arrPutnici.push(podaci[godina][putnici]);
						barPodaci.push({ name: godina, type: 'bar', data: arrPutnici });
					}
				}
				this.vBarMap.legend.data = barLegend;
				this.vBarMap.series = barPodaci;
				this.vBarMap.title.text = this.$t('dashboard.putniciPoKalendaru');
			});
		},
		heatmapOpcije() {
			return this.$get('statistikaHeatmapOpcije').then(data => {
				Object.keys(data).forEach((godina) => {
					this.statistika.godine.push(godina);
					this.heatMap.godina = godina;
					this.statistika.linije[godina.toString()] = data[godina];
					this.heatMap.idLinije = data[godina][0].id;
				});
				this.daniHeatmap();
			});
		},
		nacinProdaje() {
			this.vPieChartKupljeneKarteLoader = false;
			return this.$get('statistikaNacinProdaje').then(data => {
				this.vPieChartKupljeneKarte.series.data = [
					{ value: data.web, name: 'web' },
					// { value: data.kes, name: 'kes' },
					{ value: data.mobile, name: 'mobile' },
					{ value: data.staro, name: 'staro' }
				];
				this.vPieChartKupljeneKarteLoader = true;
			});
		},
		etapaKarataProdaje() {
			this.vPieChartEtapaKarteLoader = false;
			return this.$get('statistikaEtapaKarata').then(data => {
				this.vPieChartEtapaKarte.series.data = [
					{ value: data.etapa1, name: this.$t('dashboard.etapa1') },
					{ value: data.etapa2, name: this.$t('dashboard.etapa2') },
					{ value: data.etapa3, name: this.$t('dashboard.etapa3') },
					{ value: data.etapa4, name: this.$t('dashboard.etapa4') }
				];
				this.vPieChartEtapaKarteLoader = true;
			});
		},
		daniHeatmap() {
			let options = null;
			if (!this.heatMap.idLinije || !this.heatMap.godina) {
				this.vHeatMap.calendar.range = '';
				this.vHeatMap.series.data = [];
			}
			else
				options = { godina: this.heatMap.godina, idLinija: this.heatMap.idLinije, izSrbije: this.heatMap.odakleIde };
			this.vHeatMap.series.data = [];
			this.vHeatMap.calendar.range = this.heatMap.godina;
			this.vHeatMapLoading = true;
			return this.$get(options === null ? 'statistikaGodinaHeatmap' : 'statistikaDaniHeatmapOptions', options === null ? { godina: this.heatMap.godina, izSrbije: this.heatMap.odakleIde } : options).then(data => {
				this.heatMap.podaciSvi = data;
				let arrData = [];
				let max = 0;
				for (let datum in data) {
					let podaciZaData = 0;
					if (options !== null) {
						if (this.heatMap.odakleIde)
							podaciZaData = data[datum]['broj_karata1'] ? data[datum]['broj_karata1'] : 0;
						else
							podaciZaData = data[datum]['broj_karata2'] ? data[datum]['broj_karata2'] : 0;
					}
					else
						podaciZaData = data[datum]['broj_karata'];

					max = max < parseInt(podaciZaData) ? parseInt(podaciZaData) : max;
					arrData.push([datum, podaciZaData]);
				}
				this.vHeatMap.series.data = arrData;
				this.vHeatMap.visualMap.max = max;
				this.vHeatMapLoading = false;
			});
		},
		getStatistikaSearchLog() {
			this.statistika.searchLog.load = true;
			let params = {};
			Object.assign(params, this.statistika.searchLog.pagination);
			return this.$get('statistikaSearchLog', params).then(data => {
				this.statistika.searchLog.data = data.content;
				this.statistika.searchLog.pagination.page = data.pagination.page;
				this.statistika.searchLog.pagination.limit = data.pagination.limit;
				this.statistika.searchLog.pagination.total = data.pagination.total;
				this.statistika.searchLog.load = false;
			});
		}
	},
	components: {
		posete: require('../components/statistika/posete').default
	}
};
</script>

<style lang="scss" scoped>
	.row1 {
		background-color: #67BE8A; // Placene
	}
	.row0 {
		background-color: #8C8F98; // Cekanje
	}
	.row-1-128 {
		background-color: #FF7575; // Neuspelo
	}
	.row-78 {
		background-color: #D98BB5; // Greske
	}
	.row110 {
		background-color: #FFD77E; // Storno
	}
	.row120 {
		background-color: #1AB4EF; // Refund
	}
	.row777 {
		background-color: rgba(0, 255, 46, 0.44); // six rezervacija
	}

	.el-row {
		margin-bottom: 20px;
	}
	.box-dash {
		min-height: 127px;
		h2 {
			min-height: 35px;
		}
	}
	.el-table .cell {
		white-space: normal;
	}
	.el-divider--horizontal {
		margin: 0;
	}
</style>
