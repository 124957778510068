<template>
	<el-row>
		<el-row>
			<el-date-picker
					@change="statistikaPosete()"
					:picker-options="pickerOptions"
					:clearable="false"
					:editable="false"
					v-model="datum"
					type="daterange"
					value-format="yyyy-MM-dd"
					format="dd.MM.yyyy"
					range-separator="-">
			</el-date-picker>
		</el-row>
		<el-row v-loading="loading">
			<v-chart style="width: auto" :autoresize="true" :options="vBarMap"/>
		</el-row>
	</el-row>
</template>

<script>
export default {
	name: 'posete',
	data() {
		return {
			loading: false,
			datum: [this.moment().subtract(30, 'days').toISOString(), this.moment().toISOString()],
			pickerOptions: {
				firstDayOfWeek: 1
			},
			vBarMap: {
				title: {
					text: this.$t('dashboard.broj_poseta')
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					data: []
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				yAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				xAxis: {
					type: 'category',
					data: []
				},
				series: []
			}
		};
	},
	mounted() {
		this.statistikaPosete();
	},
	methods: {
		statistikaPosete() {
			this.loading = true;
			return this.$get('statistikaPosete', { od: this.moment(this.datum[0]).format('Y-M-D'), do: this.moment(this.datum[1]).format('Y-M-D') }).then(data => {
				let barLegend = [];
				let ip = [];
				let uip = [];
				data.forEach(d => {
					ip.push(d.broj_poseta);
					uip.push(d.broj_poseta_unikat_ip);
					barLegend.push(this.$utils('formatDate', d.datum));
				});
				let barPodaci = [
					{ name: this.$t('dashboard.broj_poseta'), type: 'bar', data: ip },
					{ name: this.$t('dashboard.broj_poseta_unikat_ip'), type: 'bar', data: uip }
				];
				this.vBarMap.xAxis.data = barLegend;
				this.vBarMap.series = barPodaci;
				this.loading = false;
			});
		}
	}
};
</script>

<style scoped>

</style>
